@use '../../../../styles/imports';

.infoItem {
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-column-gap: 20px;
  align-items: center;
  border-bottom: solid 1px var(--card-border-color);
  padding-bottom: 20px;

  &:last-of-type {
    border: none;
    padding-bottom: 0;
  }

  .content {
    width: 100%;
    display: grid;
    grid-template-columns: auto min-content;
    place-items: center start;
    gap: 10px;

    @include imports.media(S) {
      grid-template-columns: 1fr;
      padding-left: 0;
    }

    .contentInner {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      overflow: hidden;
    }

    p {
      // TODO: email address need special word break treatment
      overflow: hidden;
      text-overflow: ellipsis;
    }

    label {
      color: var(--card-text-secondary-color);
    }
  }
}
