@use '../../../../../styles/imports';

.emergencyContact {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.3em;
  margin-right: 0.3em;

  .contacts {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @include imports.media(S) {
      width: fit-content;
      flex-direction: column;
    }
  }

  .contactCard {
    background-color: var(--canvas-bg-color);
    border: 1px solid var(--card-border-color);
    padding: 2rem;
    border-radius: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @include imports.media(S) {
      grid-template-columns: repeat(1, 1fr);
    }

    .item,
    .itemBig {
      display: flex;
      flex-direction: column;

      > label {
        padding-bottom: 0.5rem;
      }
    }

    .itemBig {
      grid-column: 1 / span 2;

      @include imports.media(S) {
        grid-column: unset;
      }
    }
  }
}
